.bgLinearGreen {
    background: linear-gradient(to bottom right, #2E9E6C, #FFFFFF, #FFFFFF);
}

.bgLinearGreenReverse {
    background: linear-gradient(to bottom left, #2E9E6C, #FFFFFF);
}

.btnLogin {
    background-color: #000;
    color: #FFF;
}

.btnLogin:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

.fsWarning {
    font-size: 0.9rem;
}

.h5 {
    height: 5%;
}

.h10 {
    height: 10%;
}
.h20 {
    height: 20%;
}
.h70 {
    height: 70%;
}
.h90 {
    height: 90%;
}

.w10 {
    width: 10%;
}
.w20 {
    width: 20%;
}