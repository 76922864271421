.btn-active {
    background-color: #dffd84;
}

.user-card {
    background: #fff;
}

.user-card:hover {
    background-color: #F1FFCC;
    transition: ease-in-out, 1000ms;
}

.rating-scale {
    font-size: 0.8rem;
    /* background-color: #f8ffe6; */
}

