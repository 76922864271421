.color-nav-item {
    background-color: rgba(255, 255, 255, 0.1);
}

.nav-item:hover {
    background-color: rgba(255, 255, 255, 0.3);
    cursor: pointer;
}

.bg-green {
    background-color: #F1FFCC;
}

.no-border-on-focus:focus {
    outline: none;
    border: none;
}

.btn-search {
    background-color: #F5F5F5;
}

.color-dark-light {
    color: red;
}

.btn-next:hover {
    background-color: #dffd84;
}

/* .pos-edge {
    bottom: 5%;
    bottom: 1rem;
    right: 2%;
} */
.bg-custom-view {
    background-color: transparent;
    background-image: linear-gradient(rgba(255,255,255,0), rgba(0,0,0,1));
}

.btn-add:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.form-select {
    font-size: 0.9rem;
}