.color-nav-item {
    background-color: rgba(255, 255, 255, 0.1);
}

.nav-item:hover {
    background-color: rgba(255, 255, 255, 0.3);
    cursor: pointer;
}

.no-border-on-focus:focus {
    outline: none;
    border: none;
}

.btn-search {
    background-color: #F5F5F5;
}

.bg-green {
    background-color: #dffd84;
}

.btn-next:hover {
    background-color: #F1FFCC;
}

tr {
    border-top: 1px solid #eee;
}

td, th {
    padding: 0.8rem;
    border-right: 1px solid #eee;
}

.text-color-red {
    --bs-table-color: red; /* Replace with your desired color */
    --bs-table-striped-color: red; /* Replace with your desired color for striped rows */
    --bs-table-hover-color: red;
}