.App {
  text-align: center;
  font-family: 'Inter', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

:root {
  --font-size-xs: 0.75rem;
  --font-size-sm: 1rem;
  --font-size-md: 1.25rem;
  --font-size-lg: 1.5rem;
  --font-size-xl: 2rem;
}

@media (min-width: 576px) {
  :root {
      --font-size-xs: 0.1rem;
      --font-size-sm: 0.3rem;
      --font-size-md: 0.6rem;
      --font-size-lg: 2rem;
      --font-size-xl: 2.5rem;
  }
}

@media (min-width: 768px) {
  :root {
      --font-size-xs: 0.3rem;
      --font-size-sm: 0.5rem;
      --font-size-md: 0.8rem;
      --font-size-lg: 2.5rem;
      --font-size-xl: 3rem;
  }
}

@media (min-width: 992px) {
  :root {
      --font-size-xs: 0.6rem;
      --font-size-sm: 0.8rem;
      --font-size-md: 1.1rem;
      --font-size-lg: 3rem;
      --font-size-xl: 3.5rem;
  }
}

@media (min-width: 1200px) {
  :root {
      --font-size-xs: 0.7rem;
      --font-size-sm: 0.9rem;
      --font-size-md: 1.2rem;
      --font-size-lg: 3.5rem;
      --font-size-xl: 4rem;
  }
}

.responsive-font {
  font-size: var(--font-size-sm);
}

.responsive-font-xs {
  font-size: var(--font-size-xs);
}

.responsive-font-sm {
  font-size: var(--font-size-sm);
}

.responsive-font-md {
  font-size: var(--font-size-md);
}

.responsive-font-lg {
  font-size: var(--font-size-lg);
}

.responsive-font-xl {
  font-size: var(--font-size-xl);
}
